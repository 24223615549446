import { Component, OnInit, inject } from '@angular/core';

import { NavbarComponent } from '../../../shared/component/navbar/navbar.component';
import { SpinnerComponent } from '../../../shared/component/spinner/spinner.component';
import { FooterNavbarComponent } from '../../../shared/component/footer-navbar/footer-navbar.component';
import { Router, RouterLink } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { classRoomService } from '../../../shared/services/class-room.service';
import { ResultsService } from '../../../shared/services/results.service';
import { selectTeacherObject } from '../../../../state/teacher/teacher.selector';

// amCharts imports
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TeacherState } from '../../../shared/interfaces/store';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HindiNumberPipe } from "../../../shared/pipe/hindi-number.pipe";

@Component({ 
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    NavbarComponent,
    FooterNavbarComponent,
    SpinnerComponent,
    RouterLink,
    TranslateModule,
    HindiNumberPipe
],
  providers: [classRoomService, ResultsService],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css',
})
export class DashboardComponent  implements OnInit{
  private _classRoomService = inject(classRoomService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private _resultService = inject(ResultsService);
  private store = inject(Store);
  private translate = inject(TranslateService);

  teacherName: string = 'Mr. John Doe';
  result: any = [];
  userData: any;
  class_id: number = 1;
  school_id: number = 1;
  loader: boolean = false;
  standard: string = 'Classroom';
  division: string = 'A';
  navbarDisplay: string = 'dashboard';
  classResult: number = 0;
  NoResults: boolean = false; 
  otherClassrooms : any;
  globalObject$: Observable<TeacherState>;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    this.globalObject$ = this.store.select(selectTeacherObject);

    this.globalObject$.subscribe({
      next: (data) => {
        console.log('globalObject', data);
      },
      error: (err) => {
        console.error('Error:', err);
      },
      complete: () => {
        console.log('Subscription completed');
      },
    });
     
  }

  ngOnInit() {
    if (typeof window !== 'undefined' && window.localStorage) {
      this.userData = window.localStorage.getItem('user');
      this.userData = JSON.parse(this.userData);
      this.class_id = this.userData.class_id;
      this.school_id = this.userData.school_id;
      this.teacherName = this.userData.teacher_name;
         
      this._classRoomService
        .getClassRoomByTeacherID(this.userData.teacher_id)
        .subscribe({
          next: data => {
            this.result = data;

            this.otherClassrooms = this.result.data;
            this.class_id = this.result.data[0].class_id;
            this.standard = this.result.data[0].standard;
            this.division = this.result.data[0].division;

            this.loader = true;

            //creating overall class result piechart
            this.getClassOverallResult();
          },
          error: error => {
            console.error('Error:', error);
          },
        });
    } else {
      this.router.navigate(['/login']);
    }
  }



  goToClassroom(): void {
    this.router.navigate(['/class-room', this.class_id, this.teacherName]);
  }

  goToConnectWithParents(){
    this.router.navigate(['/connect-with-parents', this.class_id]);
  }

  goToStatsResults(){
    this.router.navigate(['/stats-results']);
  }

  createChart(result: number) {
    // Create root and chart
    const root = am5.Root.new('chartdiv');

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
      })
    );

    const data = [
      {
        status: 'Done',
        result: result,
        sliceSettings: {
          fill: am5.color(0x00FFF0), // light-blue
          stroke: am5.color(0x000000),
        },
      },
      {
        status: 'NotDone',
        result: 100 - result, // 100-result, because result is in percentage
        sliceSettings: {
          fill: am5.color(0xE5E5E5), // light-gray
          stroke: am5.color(0x000000),
        },
      },
    ];

    // Create series
    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: 'Series',
        valueField: 'result',
        categoryField: 'status',
        // legendLabelText: "none"
      })
    );

    // annimations
    series.animate({
      key: "startAngle",
      to: result,
      duration: 3000,
      easing: am5.ease.yoyo(am5.ease.linear)
    });

    // adding colours
    series.slices.template.setAll({
      templateField: 'sliceSettings',
      showTooltipOn: 'click',
    });

    //hiding the labels
    series.labels.template.setAll({
      maxWidth: 1,
      oversizedBehavior: 'hide', // to truncate labels, use "truncate"
    });

    series.ticks.template.set("visible", false);

    series.data.setAll(data);

    series.appear();
    chart.appear();
  }

  getClassOverallResult() {
    this._resultService
      .getClassOverallResult(this.class_id)
      .subscribe((data: any) => {
        if (data.message === 'false') {
          this.NoResults = true; // meaning , not a single assessment is attempted from this entire class. results are not there.
          this.classResult=0;
        } else {
          let sum = 0;
          this.classResult = 0;
          let count = 0;
          for (const obj of data.data) {
            sum += obj.result;
            count++;
          }

          this.classResult = Math.floor((sum / (count * 90)) * 100); // calculating percentage. (count*90) is the max marks . sum is obtained marks.

          this.createChart(this.classResult);
        }
      });
  }
}
