import { Component, inject } from '@angular/core';
import { NavbarComponent } from '../../../../shared/component/navbar/navbar.component';
import { FooterNavbarComponent } from '../../../../shared/component/footer-navbar/footer-navbar.component';
import { NgClass } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

// amCharts imports
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

@Component({
  selector: 'app-subcat-collated',
  standalone: true,
  imports: [ NgClass, NavbarComponent , FooterNavbarComponent],
  templateUrl: './subcat-collated.component.html',
  styleUrl: './subcat-collated.component.css'
})
export class SubcatCollatedComponent {
  private route = inject(ActivatedRoute);
  private router = inject(Router);

  navbarDisplay: string = 'none';
  class_id : number = 0;
  catName: string | null = 'catname';
  averageResult: number = 0;
  pieColor: number = 0xB9B9B9;
  parentAvg: number = 35;
  teacherAvg: number = 67;
  collatedAvg: number = 48;

  constructor(){
    console.log("constructor called !!!");
    this.catName = this.route.snapshot.paramMap.get('catName');
    this.averageResult = Number(this.route.snapshot.paramMap.get('average'));
    console.log("catName recieved is : ",this.catName);
    console.log("average recieved is : ",this.averageResult);
  }

  ngOnInit(){
    console.log("onInit called !!!");
    // setting piechart Color
    if(this.catName==="Basic"){
      this.pieColor=0x4FD6FF; // blue color for basic
    }else{
      if(this.averageResult<=25){
        this.pieColor=0xFF5449; // red color
      }else if(this.averageResult && this.averageResult<=65){
        this.pieColor=0xFFEE52; // yellow color
      }else if(this.averageResult>=66){
        this.pieColor=0xABFF58; // green color
      }
    }
    this.createPieChart("chartDiv" , this.averageResult, this.pieColor);
  }


  createPieChart(chartId: string, result: number, color: number) {
    const root = am5.Root.new(chartId);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        // layout: root.verticalLayout,
      })
    );

    root.setThemes([am5themes_Animated.new(root)]);

    // pieChart data
    const data = [
      {
        status: 'Done',
        value: result,
        sliceSettings: {
          fill: am5.color(color), // score color
          stroke: am5.color(0x000000),
        },
      },
      {
        country: 'NotDone',
        value: 100 - result,
        sliceSettings: {
          fill: am5.color(0xffffff), // white
          stroke: am5.color(0x000000),
        },
      },
    ];

    // Create series
    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: 'Series',
        valueField: 'value',
        categoryField: 'status',
      })
    );

    // adding colours
    series.slices.template.setAll({
      templateField: 'sliceSettings',
      showTooltipOn: 'click',
    });

    // hiding labels
    series.labels.template.setAll({
      maxWidth: 1,
      oversizedBehavior: 'hide',
    });
    // series.labels.template.setAll("hide");

    series.ticks.template.set('visible', false);

    // annimations
    series.animate({
      key: 'startAngle',
      to: result,
      duration: 3000,
      easing: am5.ease.yoyo(am5.ease.linear),
    });

    series.data.setAll(data);
    series.appear();
    chart.appear();
  }
}
