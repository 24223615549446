<app-navbar [teacherName]="teacherName" [standard]="standard" [division]="division"
[totalStudents]="totalStudents"></app-navbar>
<section class="d-flex justify-content-center align-items-center flex-column">
  <app-namesection [studentName]="studentName" [age]="age"></app-namesection>

  @if (!content) {
    <div id="result" class="parent-container">
      <div class="catName">
        <h4 class="mb-2 mt-2">{{cat_name}}</h4>
        <div class="downloadBtn" (click)="screenshot()" role="presentation">
          <img src="../../../../assets/img/download_icon.png" alt="">
        </div>
      </div>
      <!-- graph-section -->
      <div id="chartdiv" class="chartdiv mt-2" style="width:90%; height: 45vh;"></div>
      <!-- <div class="mb-2 d-flex align-items-center justify-content-center flex-column">
      <span style="font-size: 0.7rem;">*Above 80% shows completed all the milestones</span>
      <span style="font-size: 0.7rem;">*Below 10% shows Niether milestone is completed</span>
    </div> -->
    <p class="heading_text">• {{'subresult.teacherRemark' | translate}}</p>
    @for (obj of subcatData; track obj) {
      <div class="teacher-remarks-container ">
        <div class="teacher-remarks-card" >
          <p class="heading_text">{{obj.hasOwnProperty('cat_name_hi') ? obj.cat_name_hi : obj.cat_name}} </p>
          <!-- displaying saved remarks -->
          @if (obj.remarks) {
            <p class="remark-text">{{obj.remarks}}</p>
          } @else {
            <!-- displaying default remarks // remove default remarks after 2-3 builds-->
            @if (obj.result<=26) {
              <p>{{studentName.split(' ')[0]}} {{'result.lagging' | translate}} {{obj.hasOwnProperty('cat_name_hi') ? obj.cat_name_hi : obj.cat_name}} {{ 'result.development' | translate}}</p>
            }
            @if (obj.result>=27 && obj.result<=66) {
              <p>{{studentName.split(' ')[0]}} {{'result.encouraged' | translate}} {{obj.hasOwnProperty('cat_name_hi') ? obj.cat_name_hi : obj.cat_name}} {{ 'result.development' | translate}}</p>
            }
            @if (obj.result>=67) {
              <p>{{studentName.split(' ')[0]}} {{'result.onTrack' | translate}} {{obj.hasOwnProperty('cat_name_hi') ? obj.cat_name_hi : obj.cat_name}} {{ 'result.development' | translate}}</p>
            }
            <!-- <p class="remark-text">No Remark</p> -->
          }
        </div>
        @if (checkImageState(obj.images)) {
          <div class="photos-card">
            <div class="photo-preview">
              <ul class="photos" >
                @for (image of obj.images; track image) {
                  <li class="photos">
                    <img class="photo" [src]="image" alt="">
                  </li>
                }
              </ul>
            </div>
          </div>
        }
      </div>
    }
    <div class="buttonDiv">
      <!-- <button class="backButton" (click)="screenshot()">Download</button> -->
      <button class="backButton" (click)="goBack()">{{'back' | translate}}</button>
    </div>
  </div>
} @else {
  <div class="noData d-flex align-items-center justify-content-center flex-wrap">
    <span>
      {{'subResult.atLeastOneMilestone' | translate}}
    </span>
  </div>
}

</section>




<app-footer-navbar [class_id]="class_id"></app-footer-navbar>