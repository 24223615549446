import { Component, inject } from '@angular/core';
import { NavbarComponent } from '../../../shared/component/navbar/navbar.component';

import { UserService } from '../../../shared/services/user.service';

@Component({
  selector: 'app-teacherprofile',
  standalone: true,
  imports: [NavbarComponent],
  providers: [UserService],
  templateUrl: './teacherprofile.component.html',
  styleUrl: './teacherprofile.component.css'
})
export class TeacherprofileComponent {
  private _userService = inject(UserService);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);


  constructor() {
  }

  userData: any;
  teacherName: string = "Mr. John Doe";
  teacher_id: number = 1;
  teacherContact: number = 101;
  teacherGender: string = 'gender';
  teacherEmail: string = 'email';
  teacherCode: string = 'teacherCode';
  // teacherDob:string='00/00/0000';
  navbarDisplay: string = 'teacherProfile';


  ngOnInit() {

    if (typeof window !== 'undefined' && window.localStorage) {
      this.userData = window.localStorage.getItem('user');
      this.userData = JSON.parse(this.userData);

      this.teacherName = this.userData.teacher_name;
      this.teacher_id = this.userData.teacher_id;
    }

    // API call
    this.getTeacherInfoById();
  }

  // API call for getting the teacher details 
  getTeacherInfoById() {
    if (typeof window !== 'undefined' && window.localStorage) {
      this._userService.getTeacherInfoById(this.teacher_id).subscribe((data: any) => {

        this.teacherContact = data.data[0].teacher_mobile;
        this.teacherCode = data.data[0].teacher_code;
        this.teacherEmail = data.data[0].teacher_email;

        if (data.data[0].teacher_gender === 0) {
          this.teacherGender = 'Female';
        } else this.teacherGender = 'Male';

      });
    }

  }

  goBack() {
    window.history.back();
  }

}
