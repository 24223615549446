import { Component, OnInit, inject } from '@angular/core';

import { NavbarComponent } from '../../../shared/component/navbar/navbar.component';
import { FooterNavbarComponent } from '../../../shared/component/footer-navbar/footer-navbar.component';
import { ParentsService } from '../../../shared/services/parents.service';
import { Router } from '@angular/router';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-invite-confirm',
  standalone: true,
  imports: [NavbarComponent,FooterNavbarComponent, TranslateModule],
  providers: [ParentsService],
  templateUrl: './invite-confirm.component.html',
  styleUrl: './invite-confirm.component.css'
})
export class InviteConfirmComponent implements OnInit {
  private parentService = inject(ParentsService);
  private router = inject(Router);
  private translate = inject(TranslateService);


  navbarDisplay:string = "inviteConfirm";
  
  parentName:string ="parentName";
  parentContact:number = 1122334455;
  parentEmail:string = "parent@gmail.com";
  child_id : number = 1;
  childName:string = "childName";
  childAge:number = 3.4;
  childDob:string = "23/04/2019";
  childGender:string = "gender";

  class_id : any = 0 ;
  parentContactStatus : boolean = false;
  parent_child_details: any;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);
  // sessionStorage.getItem("Parent_Child_Details");
  constructor() { }

  ngOnInit(){
    if(typeof window !== "undefined"){
      this.parent_child_details = window.sessionStorage.getItem("parent_details");
       console.log("this.parent_child_details", this.parent_child_details);
      if(this.parent_child_details){
        this.parent_child_details = JSON.parse(this.parent_child_details);
      }
      this.class_id = window.sessionStorage.getItem("class_id") || 1;
      this.class_id=Number(this.class_id);
      console.log("class id in invite pranay confirm component is : ",this.class_id);
      console.log("type of class id is : ",typeof(this.class_id));
    

   this.parentName = this.parent_child_details.parent_name + ' ' + this.parent_child_details.parent_last_name;
   this.parentContact = this.parent_child_details.parent_mobile;
   this.parentContactStatus = (this.parent_child_details.parent_mobile || this.parent_child_details.parent_mobile !== 'null') ? true : false;
   this.parentEmail = this.parent_child_details.parent_email;
   this.child_id = this.parent_child_details.child_id;
   this.childName = this.parent_child_details.child_name;
   this.childGender = this.parent_child_details.gender === 0 ? "Female" : "Male";
   this.childDob = `${this.parent_child_details.child_born_date}/${this.parent_child_details.child_born_month}/${this.parent_child_details.child_born_year}`
    console.log("hello world")
  }
}

  confirm(){
    this.parentService.sendSchoolConnect(this.child_id,"CONNECTED").subscribe({
      next: (data: any) => {

        if (data.status == 200) {
          this.router.navigate(['/connect-with-parents',this.class_id]);
        } else {
          alert("something went Wrong");
        }
      },
      error: (error: string) => {
        console.error('Error:', error);
      },
    });
  }
  goback(){
    window.history.back();
  }
}
