@if (loader) {
<div class="spinner">
  <app-navbar [teacherName]="teacherName" [schoolNavBar]="false" [navbarDisplay]="none" [standard]="standard"
    [division]="division" [totalStudents]="totalStudents"></app-navbar>
  <section class="milestone-content w-100 d-flex justify-content-between align-items-center flex-column">
    <app-namesection class="studentName" [milestonePage]="true" [studentName]="studentName"
      [age]="age"></app-namesection>
    <div class="subcat_name mt-3">
      {{ subcat_name }}
    </div>
    <div class="scrollable-container">
      <div class="milestone-wrapper">
        @for (milestone of milestones; track milestone) {
        <div class="milestone-item d-flex align-center flex-col">
          <img [src]="url + milestone.asmt_id + '/' + milestone.image" class="milestone-image"
            [ngClass]="{ 'green-background': milestone.green && !milestone.yellow && !milestone.red, 'red-background': !milestone.green && milestone.red && !milestone.yellow, 'yellow-background': !milestone.green && !milestone.red && milestone.yellow}"
            role="presentation" (click)="handleImageKeydown($event, milestone)" tabindex="0" [alt]="'defaultImage'"
            onerror="this.src='../../../../assets/img/image1.png';" />
          <span class="text-wrap milestone-title">{{ milestone.hasOwnProperty('milestone_title_hi') ? milestone.milestone_title_hi : milestone.milestone_title }}</span>
        </div>
        }
      </div>
    </div>
    <div class="range-container flex-cent flex-col">
      <datalist id="symbolText">
        <option value="0"></option>
        <option value="30" [label]="'milestone.Lagging' | translate"></option>
        <option value="40"></option>
        <option value="50"></option>
        <option value="60" [label]="'milestone.Encourage' | translate"></option>
        <option value="70"></option>
        <option value="80"></option>
        <option value="90" [label]="'milestone.onTrack' | translate"></option>
        <option value="100"></option>
      </datalist>
      <datalist id="values" class="numValues">
        <option value="0"   [label]="0 | hindiNumber"></option>
        <option value="10"  [label]="10 | hindiNumber"></option>
        <option value="20"  [label]="20 | hindiNumber"></option>
        <option value="30"  [label]="30 | hindiNumber"></option>
        <option value="40"  [label]="40 | hindiNumber"></option>
        <option value="50"  [label]="50 | hindiNumber"></option>
        <option value="60"  [label]="60 | hindiNumber"></option>
        <option value="70"  [label]="70 | hindiNumber"></option>
        <option value="80"  [label]="80 | hindiNumber"></option>
        <option value="90"  [label]="90 | hindiNumber"></option>
        <option value="100" [label]="100 | hindiNumber"></option>
      </datalist>
      <input type="range" id="temp" name="temp" [value]="score" (input)="onInputChange($event)" list="values" />
      <div class="buttons-section">
        <button type="button" class="backButton btn btn-warning" (click)="goBack()">{{'cancel' | translate}}</button>
        <div class="">
          <p class="range-value d-flex just-center align-center" id="rangeValue">{{'milestone.score' | translate}} <br> {{ score | hindiNumber}}%</p>
        </div>
        <button type="button" class="backButton btn btn-warning" (click)="saveMilestone()"> @if (type === 'edit') {
          <span>{{'milestone.update' | translate}}</span>
          } @if (type !== 'edit') {
          <span>{{'milestone.save' | translate}}</span>
          }</button>
      </div>
    </div>
    <!-- <div class="mile-score mt-2 ps-2 pe-2">
      <span class="fw-semibold">Score : </span>
      <span class="fw-semibold text-danger">{{arrowLeft}}</span>
    </div> -->
    <!-- <div class="alertbox" [ngClass]="{'show': milestoneToast}">
    <span class="closebtn" (click)="closeAlert()" role="presentation">&times;</span>
    <p>To save the assessment, you must assess at least one milestone</p>
  </div> -->
    <div class="teacher-remarks-container flex-cent flex-col">
      <div class="camera">
        @if (type !== 'edit' ) {
        <span class="camera_text">{{'milestone.addRemark' | translate}}</span>
        } @else {
        <span class="camera_text">{{'milestone.updateRemark' | translate}}</span>
        }
        <!-- (click)="startSpeechRecognition()"  -->
        <!-- <button #micButton class="mic flex-cent" (click)="pressed()">
      <img src="../../../../assets/img/mic.svg" alt="" srcset="">
    </button> -->
        @if (type !== 'edit') {
        <div class="camera_btn flex-cent" (click)="goToCamera()" role="presentation">
          <img class="carmera_icon" src="../../../../assets/img/camera_icon .png" alt="..." />
        </div>
        }
      </div>
      <div class="remark">
        <textarea id="remark" class="teacher-remark" [value]="remarkText" type="text" [placeholder]="'milestone.teacherRemark' | translate "></textarea>
      </div>
      <p class="remark-message">{{'milestone.photosNote' | translate}}</p>
    </div>
  </section>
  <app-footer-navbar [class_id]="class_id"></app-footer-navbar>
</div>
} @else {
  <app-spinner></app-spinner>
}