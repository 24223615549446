import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationConfig } from '../../config/config';

@Injectable({
  providedIn: 'root'
})
export class ParentsService {
  private http = inject(HttpClient);
  private config = inject(ApplicationConfig);


  url :string = 'http://localhost:8080';
  token :string | null = "";

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
      const config = this.config;
 
      this.url =  config.getAPIEnvironment();
  }

  getParentsByClassId(class_id : number):Observable<object>{
    if(typeof window !== 'undefined' && window.localStorage){
      this.token = localStorage.getItem('token');
    }

    const reqHeaders : HttpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token,
      'Content-Type': 'application/json'
    });

    // testing 
    console.log(`url for getting parents details : ${this.url}/parents/getParentsByClassId/${class_id}`);
    return this.http.get<any>(`${this.url}/parents/getParentsByClassId/${class_id}` ,{ headers: reqHeaders });
 
  }

  sendSchoolConnect(child_id:number, status:string) : Observable<object>{ // fires updateSchoolConnectStatus api in backend
    if(typeof window !== 'undefined' && window.localStorage){
      this.token = localStorage.getItem('token');
    }

    const reqHeaders : HttpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token,
      'Content-Type': 'application/json'
    });

    const data = {
      child_id : child_id,
      status : status
    }

    return this.http.put<any>(`${this.url}/parents/schoolConnectRequest`,data ,{ headers: reqHeaders });
  }

}
