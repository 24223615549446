import { Component, inject } from '@angular/core';
import { NavbarComponent } from '../../../../shared/component/navbar/navbar.component';
import { FooterNavbarComponent } from '../../../../shared/component/footer-navbar/footer-navbar.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NgClass } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

// amCharts imports
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { HindiNumberPipe } from "../../../../shared/pipe/hindi-number.pipe";

interface subCategories{
  subcat_name:string,
  subcat_result: number,
  star : boolean
}

@Component({
  selector: 'app-subcat-summary',
  standalone: true,
  imports: [NgClass, NavbarComponent, FooterNavbarComponent, TranslateModule, HindiNumberPipe],
  templateUrl: './subcat-summary.component.html',
  styleUrl: './subcat-summary.component.css'
})
export class SubcatSummaryComponent {
  private route = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private translate = inject(TranslateModule);

  navbarDisplay : string = "none";
  values:any;
  catName:string ='category name';
  averageResult:number=0;
  pieColor:number= 0xB9B9B9; // gray color
  subcat_results:any[]=[];

  subcatSummary: any[]=[];

  homeactivities: number = 0;
  homeactivityDone: number = 0;
  worksheetDone: number = 0;
  worksheet: number = 0;
  isActivityExpanded: boolean = false;
  isWorksheetExpanded: boolean = false;
  class_id:number= 0;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);


  constructor(){

    this.values=window.sessionStorage.getItem("passing_obj");
    this.values=JSON.parse(this.values);
    console.log("recieved object from session is : ",this.values);

    this.catName=this.values.cat_name;

    if(this.catName === "Basic Profile"){
      this.catName = "Basic";
    }

    this.averageResult=this.values.averageScore;
    this.subcat_results=this.values.result;

    this.class_id= Number(this.activatedRoute.snapshot.paramMap.get('class_id'))!;
    console.log("class id in subcat summay component is :",this.class_id);

    this.subcatSummary=Object.values(this.values.subCategories); // converted object of object into array of objects

      // assigning total and done for categories for basic users
      this.subcatSummary.forEach((element: any) => {
        this.homeactivities += Number(element.homeActivity.total);
        console.log("homeactivities in forEach: ",this.homeactivities);
        this.homeactivityDone += Number(element.homeActivity.done);
        this.worksheet += Number(element.workSheet.total);
        this.worksheetDone += Number(element.workSheet.done);
      });

    console.log("only printing subcatSUmmary array : ",this.subcatSummary); 
  }


  ngOnInit(){

    // setting piechart Color
    if(this.catName==="Basic"){
      this.pieColor=0x4FD6FF; // blue color for basic
    }else{
      if(this.averageResult<=25){
        this.pieColor=0xFF5449; // red color
      }else if(this.averageResult && this.averageResult<=65){
        this.pieColor=0xFFEE52; // yellow color
      }else if(this.averageResult>=66){
        this.pieColor=0xABFF58; // green color
      }
    }
    this.createPieChart('chartDiv', this.averageResult, this.pieColor);

    
  }

  toggleExpand() {
    this.isActivityExpanded = !this.isActivityExpanded;
  }
  toggleWorksheetExpand() {
    this.isWorksheetExpanded = !this.isWorksheetExpanded;
  }


  createPieChart(chartId: string, result: number, color: number) {
    const root = am5.Root.new(chartId);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        // layout: root.verticalLayout,
      })
    );

    root.setThemes([am5themes_Animated.new(root)]);

    // pieChart data
    const data = [
      {
        status: 'Done',
        value: result,
        sliceSettings: {
          fill: am5.color(color), // score color
          stroke: am5.color(0x000000),
        },
      },
      {
        country: 'NotDone',
        value: 100 - result,
        sliceSettings: {
          fill: am5.color(0xffffff), // white
          stroke: am5.color(0x000000),
        },
      },
    ];

    // Create series
    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: 'Series',
        valueField: 'value',
        categoryField: 'status',
      })
    );

    // adding colours
    series.slices.template.setAll({
      templateField: 'sliceSettings',
      showTooltipOn: 'click',
    });

    // hiding labels
    series.labels.template.setAll({
      maxWidth: 1,
      oversizedBehavior: 'hide',
    });
    // series.labels.template.setAll("hide");

    series.ticks.template.set('visible', false);

    // annimations
    series.animate({
      key: 'startAngle',
      to: result,
      duration: 3000,
      easing: am5.ease.yoyo(am5.ease.linear),
    });

    series.data.setAll(data);
    series.appear();
    chart.appear();
  }

}
