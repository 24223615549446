import { Component, inject } from '@angular/core';

import { NavbarComponent } from '../../../shared/component/navbar/navbar.component';
import { FooterNavbarComponent } from '../../../shared/component/footer-navbar/footer-navbar.component';
import { ResultsService } from '../../../shared/services/results.service';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import { ActivatedRoute, Router } from '@angular/router';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { NamesectionComponent } from '../../../shared/component/nameSection/namesection.component';
import { GraphService } from '../../../shared/services/graph.service';
import html2canvas from 'html2canvas';
import { MilestoneService } from '../../../shared/services/milestone.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

interface params {
  studentName: string;
  age: string;
  standard: string;
  division: string;
  totalStudents: number;
  teacherName: string;
  cat_name: string;
}

@Component({
  selector: 'app-subcat-results',
  standalone: true,
  imports: [
    NavbarComponent,
    FooterNavbarComponent,
    NamesectionComponent,
    TranslateModule
  ],
  providers: [ResultsService, MilestoneService],
  templateUrl: './subcat-results.component.html',
  styleUrl: './subcat-results.component.css',
})
export class SubcatResultsComponent {
  private _resultservice = inject(ResultsService);
  private milestoneservice = inject(MilestoneService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private translate = inject(TranslateService);
  private graphService = inject(GraphService);

  schoolName: string = 'Global International School';
  teacherName: string = 'Mr. John Doe';
  standard: string = 'L.K.G';
  division: string = 'B';
  totalStudents: number = 0;
  student_id: number = 217;
  cat_id: number = 8;
  class_id: number = 1;
  studentName: string = 'shyam';
  age: string = '2.8';
  cat_name: string = 'Development';
  loader: boolean = false;
  params: params = {
    studentName: '',
    standard: '',
    division: '',
    totalStudents: 0,
    teacherName: '',
    cat_name: '',
    age: '',
  };
  content: boolean = false;

  subcatData: any[] = [];

  defaultRemark: string = 'No Remark';
  photoLink: string = 'https://bmc-space.blr1.cdn.digitaloceanspaces.com/';
  targetedLang: string = 'en';

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() { }

  ngOnInit() {
    this.student_id = Number(this.route.snapshot.paramMap.get('student_id'))!;
    this.class_id = Number(this.route.snapshot.paramMap.get('class_id'))!;
    this.cat_id = Number(this.route.snapshot.paramMap.get('cat_id'))!;
    this.params = this.route.snapshot.queryParams as params;

    this.studentName = this.params.studentName;
    this.standard = this.params.standard;
    this.division = this.params.division;
    this.totalStudents = this.params.totalStudents;
    this.teacherName = this.params.teacherName;
    this.cat_name = this.params.cat_name;
    this.age = this.params.age;
    if (typeof window !== 'undefined' && window.localStorage) {
      this.getResults();
    }
  }

  async getResults() {
    this.targetedLang = localStorage.getItem("targetedLang") || 'en';
    this._resultservice
      .getSubcatResult(this.student_id, this.class_id, this.cat_id, this.targetedLang)
      .subscribe((data: any) => {
        console.log('whole data : ', data);
        if (data.data.length == 0) {
          this.content = true;
        } else {
          this.subcatData = data.data;
          console.log('subcatData variable value is : ', this.subcatData);

          for (let obj of this.subcatData) {
            let photos: object = {};
            if (obj.images) {
              photos = JSON.parse(obj.images);
              obj.images = photos;
            }
            console.log("parced photos are: ", photos);
          }


          // default remarks logic

          // let scoreWord: string = 'Excellent';
          // for (let obj of this.subcatData) {
          //   if (obj.result <= 25) {
          //     scoreWord = 'Lagging';
          //     console.log('default remark is : ', this.defaultRemark);
          //   } else if (obj.result >= 26 && obj.result <= 66) {
          //     scoreWord = 'Encouraged';   
          //   } else if (obj.result >= 67) {
          //     scoreWord = 'On Track'; 
          //   }
          //   this.defaultRemark = `${this.studentName} is ${scoreWord} in `;
          // }

          this.graphService.createBarChart(data.data);
          // this.createChart(data.data);
        }
      });
  }

  checkImageState(image: string) {
    return image && image !== 'null';
  }

  goBack() {
    window.history.back();
  }

  screenshot() {
    // Select the element that you want to capture
    const captureElement = document.querySelector(
      '#result'
    ) as HTMLElement | null;
    // Call the html2canvas function and pass the element as an argument
    if (captureElement) {
      html2canvas(captureElement).then(canvas => {
        // Get the image data as a base64-encoded string
        const imageData = canvas.toDataURL('image/png');

        // Do something with the image data, such as saving it as a file or sending it to a server
        // For example, you can create an anchor element and trigger a download action
        const link = document.createElement('a');
        link.setAttribute('download', 'screenshot.png');
        link.setAttribute('href', imageData);
        link.click();
      });
    } else {
      console.error('Element #result not found');
    }
  }

  
  // createChart(chartData: any) {

  //   // console.log('chartData', chartData);

  //   chartData.forEach((dataPoint: any) => {
  //     switch (true) {
  //       case dataPoint.result <= 25:
  //         dataPoint.color = am5.color('#E70B0B');
  //         break;
  //       case dataPoint.result >= 67:
  //         dataPoint.color = am5.color('#5A9D18');
  //         break;
  //       case dataPoint.result <= 66 && dataPoint.result > 25:
  //         dataPoint.color = am5.color('#FBCD18');
  //         break;
  //       default:
  //         break;
  //     }
  //   });

  //   const root = am5.Root.new('chartdiv');
  //   //  const myTheme = am5.Theme.new(root);

  //   // myTheme.rule("Grid", ["base"]).setAll({
  //   //   strokeOpacity: 1
  //   // });

  //   // myTheme.rule("Grid").setAll({
  //   //   stroke: am5.color(0xFFFFFF),
  //   //   strokeOpacity: 0
  //   // });

  //   if (root._logo) {
  //     root._logo.dispose();
  //   }

  //   root.setThemes([am5themes_Animated.new(root)]);

  //   const charts = root.container.children.push(
  //     am5xy.XYChart.new(root, {
  //       panX: false,
  //       panY: false,
  //       wheelX: 'panX',
  //       wheelY: 'zoomX',
  //       pinchZoomX: true,
  //       paddingLeft: 0,
  //       paddingRight: 1,
  //     })
  //   );

  //   // charts.set("background", am5.Rectangle.new(root, {
  //   //   fill: am5.color(0xFFFFFF)
  //   // }));

  //   const cursor = charts.set('cursor', am5xy.XYCursor.new(root, {}));
  //   cursor.lineY.set('visible', false);

  //   const xRenderer = am5xy.AxisRendererX.new(root, {
  //     minGridDistance: 0,
  //     strokeOpacity: 1,
  //   });

  //   let xAxis = charts.xAxes.push(
  //     am5xy.CategoryAxis.new(root, {
  //       maxDeviation: 0.3,
  //       categoryField: 'cat_name',
  //       renderer: xRenderer,
  //     })
  //   );

  //   if (this.targetedLang !== 'en') {
  //     xAxis = charts.xAxes.push(
  //       am5xy.CategoryAxis.new(root, {
  //         maxDeviation: 0.3,
  //         categoryField: 'cat_name_hi',
  //         renderer: xRenderer,
  //       })
  //     );
  //   }

  //   xRenderer.labels.template.setAll({
  //     oversizedBehavior: 'truncate',
  //     textAlign: 'center',
  //   });

  //   xAxis.onPrivate('cellWidth', function (cellWidth) {
  //     xRenderer.labels.template.set('maxWidth', cellWidth);
  //   });

  //   const yRenderer = am5xy.AxisRendererY.new(root, { strokeOpacity: 1 });

  //   // yRenderer.grid.template.setAll({
  //   //   stroke: am5.color(0xFFFFFF),
  //   //   strokeWidth: 0.1
  //   // });

  //   const yAxis = charts.yAxes.push(
  //     am5xy.ValueAxis.new(root, {
  //       maxDeviation: 0.3,
  //       min: 0,
  //       max: 110,
  //       strictMinMax: true,
  //       renderer: yRenderer,
  //       marginBottom: 2,
  //     })
  //   );

  //   let series = charts.series.push(
  //     am5xy.ColumnSeries.new(root, {
  //       name: 'Series 1',
  //       xAxis: xAxis,
  //       yAxis: yAxis,
  //       valueYField: 'result',
  //       sequencedInterpolation: true,
  //       categoryXField: 'cat_name',
  //       tooltip: am5.Tooltip.new(root, {
  //         labelText: '{cat_name} {valueY}',
  //       }),
  //     })
  //   );

  //   if (this.targetedLang !== 'en') {
  //     series = charts.series.push(
  //       am5xy.ColumnSeries.new(root, {
  //         name: 'Series 1',
  //         xAxis: xAxis,
  //         yAxis: yAxis,
  //         valueYField: 'result',
  //         sequencedInterpolation: true,
  //         categoryXField: 'cat_name_hi',
  //         tooltip: am5.Tooltip.new(root, {
  //           labelText: '{cat_name_hi} {valueY}',
  //         }),
  //       })
  //     );
  //   }



  //   series.bullets.push(function () {
  //     return am5.Bullet.new(root, {
  //       locationX: 0.5,
  //       locationY: 1,
  //       sprite: am5.Circle.new(root, {
  //         radius: 15,
  //         stroke: am5.color('#000'),
  //         strokeWidth: 1,
  //         fill: am5.color('#fff'),
  //         centerX: am5.percent(50),
  //         centerY: am5.percent(50),
  //       }),
  //     });
  //   });

  //   series.bullets.push(function () {
  //     return am5.Bullet.new(root, {
  //       locationX: 0.5,
  //       locationY: 1,
  //       stacked: 'up',

  //       sprite: am5.Label.new(root, {
  //         text: '{valueY}',
  //         centerX: am5.percent(50),
  //         centerY: am5.percent(50),
  //         textAlign: 'center',
  //         populateText: true,
  //       }),
  //     });
  //   });

  //   series.columns.template.adapters.add('fill', (fill, target) => {
  //     const dataItem = target.dataItem?.dataContext as {
  //       color?: { _hex: string };
  //     };
  //     if (dataItem?.color && dataItem.color._hex) {
  //       const hexColor = Number(dataItem.color._hex);
  //       const red = (hexColor >> 16) & 255;
  //       const green = (hexColor >> 8) & 255;
  //       const blue = hexColor & 255;
  //       return am5.color(`rgb(${red}, ${green}, ${blue})`);
  //     }
  //     return undefined;
  //   });

  //   series.columns.template.setAll({
  //     cornerRadiusTL: 8,
  //     cornerRadiusTR: 8,
  //     cornerRadiusBR: 8,
  //     cornerRadiusBL: 8,
  //     strokeOpacity: 0,
  //     width: 18,
  //   });

  //   xAxis.data.setAll(chartData);
  //   series.data.setAll(chartData);

  //   series.appear(1000);
  //   // chart.appear(1000, 100);
  // }

  // getting the milestone photos of child
  // getMilestonePhotos(){
  //   this.milestoneservice
  //     .fetchImages(this.student_id, this.class_id, this.cat_id)
  //     .subscribe((data: any) => {
  //       console.log('data.data', data.data);

  //     });
  // }


}
