import { Component, inject } from '@angular/core';
import { environment } from '../environments/environment';
import { RouterOutlet,RouterModule } from '@angular/router';
import { SpinnerComponent } from './shared/component/spinner/spinner.component';
import { AlertComponent } from './shared/component/alert-box/alert-box.component';
import { ToastComponent } from './shared/component/toast/toast.component';
import { NgClass } from '@angular/common';
import { NavbarComponent } from './shared/component/navbar/navbar.component';
import { PwaInstallService } from './shared/services/pwa-install.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    NgClass,
    NavbarComponent,
    ToastComponent,
    RouterOutlet,
    RouterModule,
    SpinnerComponent, 
    AlertComponent
  ],
  providers: [],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title: string = 'SAM';
  showInstallButton: boolean = false;
  showCancelButton: boolean = false;
  showUpdateNotification: boolean = false;

  private pwaInstallService = inject(PwaInstallService);
  private translate = inject(TranslateService);
  constructor(...args: unknown[]);

  constructor() {
    // Logs api origin enpoint of environment
    console.log("Logs environment",environment.apiUrl); 
    // this.translate.setDefaultLang('en');
    if (typeof window !== 'undefined' && window.localStorage) {
      const savedLanguage = localStorage.getItem('targetedLang') || 'en';
      this.translate.use(savedLanguage);    
    }
   }

  ngOnInit(): void {

    
    // Subscribe to the install prompt event to show a custom install button
    this.pwaInstallService.installPromptEvent.subscribe(canShowPrompt => {
      this.showInstallButton = canShowPrompt;
      this.showCancelButton = canShowPrompt;
    });

    this.pwaInstallService.upgradeAvailable.subscribe(isAvailable => {
      this.showUpdateNotification = isAvailable;
      if (isAvailable) {
        this.promptUserToUpdate();
      }
    });
  }

  // Trigger the install prompt when the button is clicked
  onInstallPwa(): void {
    this.pwaInstallService.showInstallPrompt();
  }

  cancel(): void {
    this.pwaInstallService.cancelInstallPrompt(); 
    this.showInstallButton = false; 
    this.showCancelButton = false; 
  }

  promptUserToUpdate(): void {
    const userConfirmed = confirm('A new version of the app is available. Would you like to reload?');
    if (userConfirmed) {
      window.location.reload();
    }
  }

}
