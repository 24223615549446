import { AfterViewInit, Component, inject } from '@angular/core';

import { NavbarComponent } from '../../../shared/component/navbar/navbar.component';
import { FooterNavbarComponent } from '../../../shared/component/footer-navbar/footer-navbar.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NamesectionComponent } from '../../../shared/component/nameSection/namesection.component';
import { ResultsService } from '../../../shared/services/results.service';
import { ToastService } from '../../../shared/services/alert/toast.service';

// amCharts imports
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';


import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HindiNumberPipe } from '../../../shared/pipe/hindi-number.pipe';
interface Category {
  id: number;
  name: string;
  shortName?: string; // not-compulsary
  chartId: string;
  result: number;
}

interface params {
  studentName: string;
  standard: string;
  division: string;
  totalStudents: number;
  teacherName: string;
  age: string;
}

@Component({
  selector: 'app-result',
  standalone: true,
  imports: [
    NavbarComponent,
    FooterNavbarComponent,
    NamesectionComponent,
    TranslateModule,
    HindiNumberPipe
],
  providers: [ResultsService],
  templateUrl: './result.component.html',
  styleUrl: './result.component.css',
})
export class ResultComponent implements AfterViewInit {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private resultService = inject(ResultsService);
  private toastService = inject(ToastService);
  private translate = inject(TranslateService)
  schoolName: string = 'Global International School';
  teacherName: string = 'Mr. John Doe';
  standard: string = 'Nursery';
  division: string = 'A';
  totalStudents: number = 20;
  studentName: string = 'John Doe';
  age: string = '4 years';
  student_id: number = 0;
  class_id: number = 0;
  schoolNavbar :boolean = true;
  refresh: number[] = [1];

  //average of all 6 categories
  averageResult: number = 0;

  pieColor: number = 0xcecece; // hexadecimal code for gray color by default

  params: params = {
    studentName: '',
    standard: '',
    division: '',
    totalStudents: 0,
    teacherName: '',
    age: '',
  };

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);
  constructor() {
    this.student_id = Number(this.route.snapshot.paramMap.get('student_id'))!;
    this.class_id = Number(this.route.snapshot.paramMap.get('class_id'))!;
    this.params = this.route.snapshot.queryParams as params;

    this.studentName = this.params.studentName;
    console.log("student name in namesection is : ",this.studentName);
    this.standard = this.params.standard;
    this.division = this.params.division;
    this.totalStudents = this.params.totalStudents;
    this.teacherName = this.params.teacherName;
    this.age = this.params.age;
  }

  // preprod database categories

  categoryResult: Category[] = [
    {
      id: 15,    // 15 for preprod , 8 for local
      name: this.translate.instant('Physical Development'),
      shortName: 'PD',
      chartId: "chartdiv2",
      result: 0
    },
    {
      id: 16, // 16 for preprod , 7 for local
      name: this.translate.instant('Communication and Language'),
      shortName: 'CL',
      chartId: "chartdiv1",
      result: 0
    },
    {
      id: 17, // 17 for preprod , 9 for local
      name: this.translate.instant('Socio-Emotional Development'),
      shortName: 'SD',
      chartId: "chartdiv3",
      result: 0
    },
    {
      id: 18, // 18 for preprod , 10 for local
      name: this.translate.instant('Problem Solving'),
      shortName: 'PS',
      chartId: "chartdiv4",
      result: 0
    },
    {
      id: 19, // 19 for preprod , 11 for local
      name: this.translate.instant('General Knowledge'),
      shortName: 'GK',
      chartId: "chartdiv5",
      result: 0
    },
    {
      id: 20, // 20 for preprod , 12 for local
      name: this.translate.instant('Arts and Creativity'),
      shortName: 'AC',
      chartId: "chartdiv6",
      result: 0
    },
  ];


  // local database categories
  // categoryResult: Category[] = [
  //   {
  //     id: 7, // 16 for preprod , 7 for local
  //     name: 'Communication and Language',
  //     shortName: 'CL',
  //     chartId: "chartdiv1",
  //     result: 0
  //   },
  //   {
  //     id: 8,    // 15 for preprod , 8 for local
  //     name: 'Physical Development',
  //     shortName: 'PD',
  //     chartId: "chartdiv2",
  //     result: 0
  //   },
  //   {
  //     id: 9, // 17 for preprod , 9 for local
  //     name: 'Socio-Emotional Development',
  //     shortName: 'SD',
  //     chartId: "chartdiv3",
  //     result: 0
  //   },
  //   {
  //     id: 10, // 18 for preprod , 10 for local
  //     name: 'Problem Solving',
  //     shortName: 'PS',
  //     chartId: "chartdiv4",
  //     result: 0
  //   },
  //   {
  //     id: 11, // 19 for preprod , 11 for local
  //     name: 'General Knowledge',
  //     shortName: 'GK',
  //     chartId: "chartdiv5",
  //     result: 0
  //   },
  //   {
  //     id: 12, // 20 for preprod , 12 for local
  //     name: 'Arts and Creativity',
  //     shortName: 'AC',
  //     chartId: "chartdiv6",
  //     result: 0
  //   },
  // ];

  ngAfterViewInit() {
    this.getCompletedMilestones();

  }
  goToSubResult(cat_name: string, id: number, result:number): void {

    if(result !== 0){
      this.router.navigate(
        ['/subcat-results', this.class_id, this.student_id, id],
        {
          queryParams: {
            cat_name: cat_name,
            studentName: this.studentName,
            age: this.age,
            schoolName: this.schoolName,
            totalStudents: this.totalStudents,
            standard: this.standard,
            division: this.division,
            teacherName: this.teacherName,
          },
        }
      );
    }
  }

  // goBack(): void {
  //   this.router.navigate(['/class-room', this.class_id, this.teacherName]);
  //   // window.history.back();
  // }

  createPieChart(chartId: string, result: number, color: number) {
    const root = am5.Root.new(chartId);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        // layout: root.verticalLayout,
      })
    );

    root.setThemes([am5themes_Animated.new(root)]);

    // pieChart data
    const data = [
      {
        status: 'Done',
        value: result,
        sliceSettings: {
          fill: am5.color(color),
          stroke: am5.color(0x000000),
        },
      },
      {
        country: 'NotDone',
        value: 100 - result,
        sliceSettings: {
          fill: am5.color(0xffffff), // white
          stroke: am5.color(0x000000),
        },
      },
    ];

    // Create series
    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: 'Series',
        valueField: 'value',
        categoryField: 'status',
      })
    );

    // adding colours
    series.slices.template.setAll({
      templateField: 'sliceSettings',
      showTooltipOn: 'click',
    });

    // hiding labels
    series.labels.template.setAll({
      maxWidth: 1,
      oversizedBehavior: 'hide',
    });
    // series.labels.template.setAll("hide");

    series.ticks.template.set('visible', false);

    // annimations
    series.animate({
      key: 'startAngle',
      to: result,
      duration: 3000,
      easing: am5.ease.yoyo(am5.ease.linear),
    });

    series.data.setAll(data);
    series.appear();
    chart.appear();
  }

  // API call for getting completed milstones and thier results

  getCompletedMilestones() {
    this.resultService
      .getCompletedMilestones(this.class_id, this.student_id)
      .subscribe((data: any) => {

        console.log("printing whole data",data);

        let avg = 0;
        let avgSum = 0;
        let completed_cat = 0;

        for (const obj of this.categoryResult) {
          const cat_id = obj.id;
          let sum = 0;
          let no_of_subcat = 0; 
          let result = 0;

          for (const i of data.data) {
            if (cat_id == i.category_id) {
              sum += i.result;
              no_of_subcat++;
            }
          }

          result = Math.round((sum / (no_of_subcat * 100)) * 100); 
         
          if (isNaN(result)) {
            result = 0;
          }
          console.log('result value is : ', result);
          obj.result = result;

          // calculating average result of all attempted categories
          if (obj.result > 0) {
            avgSum += obj.result;
            completed_cat++;
          }
          // console.log("sum value inside average for loop is : ",avgSum);
          // console.log("completed_cat value inside average for loop is : ",completed_cat);
          avg = avgSum / completed_cat;
        } // end of main for loop

        this.averageResult = Math.round(avg);


        if (this.averageResult == 0) {
          this.toastService.showAlert(this.translate.instant('result.emptyRemark'), this.translate.instant('Note'));
          window.history.back();
        }


        // finally creating peicharts
        let element = 0;
        for (const i of this.categoryResult) {
          // setting the color for result
          if (this.categoryResult[element].result <= 25)
            this.pieColor = 0xff5449; // red
          else if (
            this.categoryResult[element].result >= 26 &&
            this.categoryResult[element].result <= 65
          )
            this.pieColor = 0xffee52; // yellow
          else if (
            this.categoryResult[element].result >= 66 &&
            this.categoryResult[element].result <= 100
          )
            this.pieColor = 0xabff58; // green

          this.createPieChart(i.chartId, i.result, this.pieColor);
          element++;

        }
      });
  }
}
