
import { RouterLink, RouterModule, Router } from '@angular/router';
import { Component, Input, OnInit, inject } from '@angular/core';

import { UserData } from '../../interfaces/api';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HindiNumberPipe } from "../../pipe/hindi-number.pipe";

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    RouterLink,
    RouterModule,
    TranslateModule,
    HindiNumberPipe
],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css'
})
export class NavbarComponent implements OnInit {
  private router = inject(Router);
  private translate = inject(TranslateService);

  public schoolName: string = '';
  @Input() noBack : boolean = true;
  private schoolLogoUrl: string = 'https://bmc-space.blr1.cdn.digitaloceanspaces.com/serviceProvider/';
  @Input() schoolLogo: string = '';

  @Input() teacherName: string = 'Mr. John Doe';
  @Input() navbarDisplay: string = "show";
  @Input() schoolNavBar : boolean = true;
  @Input() standard: string = "";
  @Input() division: string = "";
  @Input() totalStudents: number = 0;
  @Input() class_id: number = 0;
  @Input() backToDash : boolean = false;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() { }
  ngOnInit() {

    // console.log("class id recieved in navbar component is : ",this.class_id);
    if (typeof window !== 'undefined' && window.localStorage) {
      const user = localStorage.getItem('user')
      if(user){
        const logo : UserData = JSON.parse(user);
        this.schoolLogo = `${this.schoolLogoUrl}${logo.school_logo}`;
        this.schoolName = logo.school_Name;
      }
    } 
  }

  goBack(){
    if(this.class_id !== 0 ){ 
      // neccessary for navigating back from subcat screen to classroom screen
      this.router.navigate(['/class-room', this.class_id, this.teacherName]);
    }else if( this.backToDash === true){ 
      this.router.navigate(['/dashboard']);
    }else{
      window.history.back();
    }
  }
}
