import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from '../../../shared/component/navbar/navbar.component';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { UserService } from '../../../shared/services/user.service';
import { MilestoneService } from '../../../shared/services/milestone.service';
import { FormsModule } from '@angular/forms';
import { SpinnerComponent } from '../../../shared/component/spinner/spinner.component';
import { FooterNavbarComponent } from '../../../shared/component/footer-navbar/footer-navbar.component';
import { NamesectionComponent } from '../../../shared/component/nameSection/namesection.component';
import { AppComponent } from '../../../app.component'

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectStudentObject } from '../../../../state/student/student.selector';
import { StudentState } from '../../../shared/interfaces/store';

import { TranslateModule,TranslateService } from '@ngx-translate/core';
import { HindiNumberPipe } from "../../../shared/pipe/hindi-number.pipe";
interface subCat {
  [x: string]: any;
  category_id: string;
  subcategory_id: number;
  cat_name: string;
  completion: string;
  created_on?: string;
  cat_name_hi?: string
}


@Component({
  selector: 'app-subcat',
  standalone: true,
  imports: [NavbarComponent, FormsModule, CommonModule, SpinnerComponent, AppComponent, FooterNavbarComponent, NamesectionComponent, TranslateModule, HindiNumberPipe],
  providers: [UserService, MilestoneService,],
  templateUrl: './subcat.component.html',
  styleUrl: './subcat.component.css'
})


export class SubcatComponent {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private milestoneService = inject<MilestoneService>(MilestoneService);
  private store = inject(Store);
  private translate = inject(TranslateService);

  
  
  targetedLang : string = 'en'; // This variable is used to check the targeted lang

  params: any;
  schoolName: string = 'Global International School';
  class_id: number = 1;
  cat_name: string = "Physical Development";
  student_id: number = 1;
  studentData: any;
  cat_id: number = 8;
  subCategories: subCat[] | null = [];
  status: string | null = "";
  dob_year: number = 2020;
  dob_date: number = 1;
  dob_month: number = 1;
  age: string = "2 yrs.";
  studentName: string = "Shyam";
  standard: string = "Nursery";
  division: string = "A";
  totalStudents: number = 20;
  teacherName: string = 'Mr. John Doe';
  loader: boolean = false;

  // for calculating piechart results
  milestones_data: any;
  peichartResult: number = 0;

  // for colorcoding of the milestones on the classroom screen
  categoryStatus: string = 'notYet';

  studentObject$: Observable<StudentState>;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);
  constructor() {
    this.studentObject$ = this.store.select(selectStudentObject);

    this.studentObject$.subscribe({
      next: (data) => {
        console.log('StudentData', data);
      },
      error: (err) => {
        console.error('Error:', err);
      },
      complete: () => {
        console.log('Subscription completed');
      },
    });
  }

  ngOnInit() {

    this.cat_id = Number(this.route.snapshot.paramMap.get('categoryId'))!;
    console.log("category id in subcat component : ", this.cat_id);
    this.cat_name = this.route.snapshot.paramMap.get('categoryName')!;
    this.cat_name = this.translate.instant(this.cat_name);
    this.student_id = Number(this.route.snapshot.paramMap.get('student_id'))!;
    this.class_id = Number(this.route.snapshot.paramMap.get('class_id'))!;
    this.params = this.route.snapshot.queryParams;

    this.dob_date = Number(this.params.dob_date);
    this.dob_month = Number(this.params.dob_month);
    this.dob_year = Number(this.params.dob_year);

    this.age = this.params.age;
    this.studentName = this.params.studentName;
    this.standard = this.params.standard;
    this.division = this.params.division;
    this.totalStudents = this.params.totalStudents;
    this.teacherName = this.params.teacherName;

    if (typeof window !== 'undefined' && window.localStorage) {
      this.targetedLang = localStorage.getItem("targetedLang") || 'en';
      this.milestoneService.getMilestoneByCategory(this.cat_id, this.class_id, this.student_id, this.dob_month, this.dob_date, this.dob_year, this.targetedLang).subscribe({
        next: (res: any) => {
          this.subCategories = res.data.milestones;
          this.loader = true;

        },
        error: (error) => {
          // Handle error
          console.error('Error:', error);
        }
      });
    }
  }

  dateConverstion(d: string): string {
    const date = new Date(d);
    return date.toLocaleDateString('en-GB');
  }

  goToMilestones(subcat : any, type: string) {
   let subCat_name = subcat.hasOwnProperty('cat_name_hi') ? subcat.cat_name_hi: subcat.cat_name 
    console.log("subCat_name",subCat_name);
    this.router.navigate(['/milestone', this.class_id, this.student_id, this.cat_id, this.cat_name,  subcat.subcategory_id, subCat_name, type], { queryParams: this.params });
  }
  // goBack(): void {
  //   this.router.navigate(['/class-room', this.class_id, this.teacherName]);
  //   // window.history.back();
  // }

  pieChartResults() {
    // checking data
    console.log("milestone data from pieChartResults() is : ", this.milestones_data);
  }
}