<app-navbar [navbarDisplay]="navbarDisplay"></app-navbar>

<div class="parent-container flex-cent flex-col">
  <div class="header-container"
    [ngClass]="{'lightGreen' : averageResult >= 66 , 'lightRed' : averageResult <= 25 , 'lightYellow' : averageResult >= 26 && averageResult <= 65 , 'basicProfile' : catName === 'Basic'}">
    <div class="heading">
      <h3 class="textBold">{{catName}}</h3>
    </div>
    <div class="piechart">
      <div id="chartDiv"></div>
      <div class="score">
        <h6>Avg.</h6>
        <h4 class="averageColor">{{averageResult}}%</h4>
      </div>
    </div>
  </div>

  <div class="subcat-container flex-cent flex-col"
    [ngClass]="{'lightGreen' : averageResult >= 66 , 'lightRed' : averageResult <= 25 , 'lightYellow' : averageResult >= 26 && averageResult <= 65 , 'basicProfile' : catName === 'Basic'}">
    <div class="heading">
      <h5 class="textBold">Sub-Categories</h5>
    </div>
    <div class="subcategory-card flex-cent flex-col">
      <div class="subcatName">
        <h5>Subcat Name</h5>
      </div>
      <div class="subCat-data flex-col">
        <!-- parent-result -->
        <div class="result flex-cent">
          <img src="../../../../../assets/img/collated_parents.png" alt="">
          <div class="progress-bar flex-cent flex-row just-between">
            <div class="progress" [style.width.%]="parentAvg" [ngClass]="{'Yellow': parentAvg >= 26 && parentAvg <= 65 , 'Red': parentAvg <= 25 , 'Green': parentAvg >= 66}">
              
            </div>
            <div class="average">
              <h6>{{parentAvg}}%</h6>
            </div>
          </div>
        </div>

        <!-- teacher-result -->
        <div class="result flex-cent">
          <img src="../../../../../assets/img/teacher.png" alt="">
          <div class="progress-bar flex-cent flex-row just-between">
            <div class="progress" [style.width.%]="teacherAvg" [ngClass]="{'Yellow': teacherAvg >= 26 && teacherAvg <= 65 , 'Red': teacherAvg <= 25 , 'Green': teacherAvg >= 66}">
              
            </div>
            <div class="average">
              <h6>{{teacherAvg}}%</h6>
            </div>
          </div>
        </div>

        <!-- average-result -->
        <div class="result flex-cent">
          <img src="../../../../../assets/img/average.png" alt="">
          <div class="progress-bar flex-cent flex-row just-between">
            <div class="progress colAvgColor" [style.width.%]="collatedAvg" >
              
            </div>
            <div class="average">
              <h6 class="averageColor">{{collatedAvg}}%</h6>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>

<app-footer-navbar [class_id]="class_id"></app-footer-navbar>