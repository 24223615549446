/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';


import { MilestoneService } from '../../../shared/services/milestone.service';
import { NavbarComponent } from '../../../shared/component/navbar/navbar.component';

import { SpinnerComponent } from '../../../shared/component/spinner/spinner.component';
import { FooterNavbarComponent } from '../../../shared/component/footer-navbar/footer-navbar.component';
import { NamesectionComponent } from '../../../shared/component/nameSection/namesection.component';
import { AlertService } from '../../../shared/services/alert/alert-service.service';
import { AlertComponent } from '../../../shared/component/alert-box/alert-box.component';
import { ToastService } from '../../../shared/services/alert/toast.service';
import { SpeechRecognitionService } from '../../../shared/services/speech-recognition.service';

import { Store } from '@ngrx/store';
import { updateMilestoneState } from '../../../../state/milestone/milestone.actions';
import { SelectedMilestone } from '../../../shared/interfaces/common';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HindiNumberPipe } from '../../../shared/pipe/hindi-number.pipe';
@Component({
  selector: 'app-milestone',
  standalone: true,
  imports: [
    NavbarComponent,
    AlertComponent,
    CommonModule,
    FormsModule,
    SpinnerComponent,
    FooterNavbarComponent,
    NamesectionComponent,
    TranslateModule,
    HindiNumberPipe
  ],
  providers: [MilestoneService],
  templateUrl: './milestone.component.html',
  styleUrl: './milestone.component.css',
})
export class MilestoneComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private alertService = inject(AlertService);
  private toastService = inject(ToastService);
  private speechRecognitionService = inject(SpeechRecognitionService);
  private milestoneService = inject<MilestoneService>(MilestoneService);
  private store = inject(Store);
  private translate = inject(TranslateService);
  @ViewChild('micButton') micButton!: ElementRef;


  // These variables are used to manage the subcats and cats of the milestone with proper student 
  catId: number = 0;
  catName: string = '';
  subCatId: number = 0;
  class_id: number = 0;
  student_id: number = 0;
  params: any = {};
  subcat_name: string = '';
  school_id: number = 1;


  //These are the top-navbar variables 
  teacherName: string = '';
  schoolName: string = '';
  totalStudents: number = 0;
  standard: any;
  division: any;
  none: string = "none";

  /**
   * following variables are used in milestoen component to manipulate the data
   * and handling the selection of milestones
   */
  score: number = 0;
  progressValue: number = 0;
  photoCount: number = 0;
  milestones: any[] = [];
  savedMilestone: any;
  selectedMilestones: any = {
    YES: [],
    SOMETIMES: [],
    NO: [],
    totalMilestones: 2,
  };

  // These two variables are used to set default image path and digital space origin endpoint
  defaultImage = '../../../../assets/img/milestone_defaultImg.png';
  url: string =
    'https://bmc-space.blr1.cdn.digitaloceanspaces.com/uploads/question/';

  //Student detailes variables
  studentName: string = '';
  age: string = '';
  dob_date: number = 0;
  dob_month: number = 0;
  dob_year: number = 0;

  //These variables are use to check loader , give notification or check 
  loader: boolean = false;
  milestoneToast: boolean = false;
  isListening: boolean = false;

  /**
   * These variales are used check wheter the milestoen is completed or not and if completed then store 
   * perticular values so we can achive desire functionality or results
   */
  type: string = 'add';
  record_id: number = 1;
  returnedFromCamera: string = 'false';
  milestoneState: string = '';
  targetedLang: string = 'en';
  remarkText: string = '';
  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);


  constructor() {
    this.params = this.route.snapshot.queryParams;

    // assigning params values to perticular variables

    this.catId = Number(this.route.snapshot.paramMap.get('categoryId'))!;
    this.subCatId = Number(this.route.snapshot.paramMap.get('sub_id'))!;
    this.subcat_name = this.route.snapshot.paramMap.get('subcat_name')!;
    this.catName = this.route.snapshot.paramMap.get('cat_name')!;
    this.student_id = Number(this.route.snapshot.paramMap.get('student_id'))!;
    this.class_id = Number(this.route.snapshot.paramMap.get('class_id'))!;
    this.type = this.route.snapshot.paramMap.get('type')!;

    this.standard = this.params.standard;
    this.division = this.params.division;
    this.totalStudents = Number(this.params.totalStudents);
    this.teacherName = this.params.teacherName;

    this.dob_date = Number(this.params.dob_date);
    this.dob_month = Number(this.params.dob_month);
    this.dob_year = Number(this.params.dob_year);
    this.age = this.params.age;
    this.studentName = this.params.studentName;
    this.milestoneState = this.params.milestoneState;
    this.returnedFromCamera = this.params.returnedFromCamera;


  }

  ngOnInit() {
    if (typeof window !== 'undefined' && window.localStorage) {
      this.targetedLang = localStorage.getItem("targetedLang") || 'en';

      this.milestoneService
        .getMilestoneBySubCategory(
          this.catId,
          this.subCatId,
          this.class_id,
          this.student_id,
          this.dob_month,
          this.dob_date,
          this.dob_year,
          this.targetedLang
        )
        .subscribe({
          next: (res: any) => {
            this.loader = true;
            /**
             * this api condion used when user wants to update aleady saved milestone
             * we get the saved milestone
             */

            if (this.returnedFromCamera === 'true') {


              this.selectedMilestones = JSON.parse(this.params.selectMilestone);
              this.milestones = JSON.parse(this.milestoneState);



              this.score = Number(this.params.score);
              console.log("this.params", this.params.remark)
              this.remarkText = this.params.remark;            

              const photoUploaded = this.params.photoUploaded;

              if (
                this.returnedFromCamera === 'true' &&
                photoUploaded === 'true'
              ) {
               
              }
              // if (
              //   this.returnedFromCamera === 'true' &&
              //   photoUploaded === 'false'
              // ) {
              //   this.toastService.showAlert('No Photos were Uploaded', 'Note');
              // }

              // getting the uploaded images count
              this.getUploadedImagesCount();
            } else {
              if (this.type === 'edit') {
                // edit milestone screen
                this.milestones = res.data;

                this.getSavedMilestones(
                  this.subCatId,
                  this.catId,
                  this.class_id,
                  this.student_id
                );

              } else {
                // brand new milestone screen

                this.milestones = res.data.map((milestone: any) => ({
                  ...milestone,
                  red: false,
                  green: false,
                  yellow: false,
                  clicks: 0,
                }));
              }
            }

            // this.selectedMilestones.totalMilestones = this.milestones.length;
          },
          error: (error: any) => {
            console.error('Error fetching milestones:', error);
          },
        });
    }
  }


  pressed() {
    const micButtonEl = this.micButton.nativeElement;

    this.checkMicrophonePermission()
      .then((permissionGranted) => {
        if (permissionGranted) {
          // If permission is granted, toggle recognition
          this.toggleRecognition(micButtonEl);
        } else {
          console.error('Microphone access denied.');
        }
      })
      .catch((error) => {
        console.error('Error checking microphone permission:', error);
      });

  }

  async checkMicrophonePermission(): Promise<boolean> {
    return navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        stream.getTracks().forEach(track => track.stop());
        return true;
      })
      .catch((err) => {
        return false;
      });
  }

  toggleRecognition(micButtonEl: any) {
    if (!this.isListening) {
      console.log('started');
      this.speechRecognitionService.startListening();

      this.isListening = true;
      micButtonEl.classList.add('shrink-mic');
      micButtonEl.classList.add('rotate-circle');
    } else {
      const textArea = document.getElementById('remark') as HTMLTextAreaElement;

      if (
        this.speechRecognitionService.getTranscript() &&
        this.speechRecognitionService.getTranscript() !== ''
      ) {
        this.remarkText = this.speechRecognitionService.getTranscript();
      }
      console.log('stoped');

      this.speechRecognitionService.stopListening();
      this.isListening = false;

      micButtonEl.classList.remove('shrink-mic');
      micButtonEl.classList.remove('rotate-circle');
    }
  }


  /**
   * This fucntion is used to get the value of the input field
   * @param event
   */
  onInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    this.score = parseInt(input.value);
    this.addDefaultRemark(this.score);
  }

  handleImageKeydown(event: MouseEvent, image: any) {
    try {
      if (!image.clicks) {
        image.clicks = 0;
      }
      console.log("seleced before", this.selectedMilestones);

      console.log("clicked ", image.clicks);

      // green color on one click means yes
      // yellow color on two click means sometimes
      // red color on three click means no

      if (image.clicks === 0) {
        image.green = true;
        image.red = false;
        image.yellow = false;
        console.log("isndie click 0")
        console.log("seleced", this.selectedMilestones);
        this.selectedMilestones.YES.push(image.milestone_id);
        image.clicks = 1;
      } else if (image.clicks === 1) {
        image.green = false;
        image.yellow = true;
        image.red = false;
        console.log("isndie click 1")
        console.log("seleced", this.selectedMilestones);
        this.selectedMilestones.YES.pop(image.milestone_id);
        this.selectedMilestones.SOMETIMES.push(image.milestone_id);
        image.clicks = 2;
      } else if (image.clicks === 2) {
        image.green = false;
        image.yellow = false;
        image.red = true;
        console.log("isndie click 2")
        console.log("seleced", this.selectedMilestones);
        this.selectedMilestones.SOMETIMES.pop(image.milestone_id);
        this.selectedMilestones.NO.push(image.milestone_id);
        image.clicks = 3;
      } else {
        console.log("isndie else");
        console.log("seleced", this.selectedMilestones);
        image.red = false;
        image.green = false;
        image.yellow = false;
        this.selectedMilestones.NO.pop(image.milestone_id);
        image.clicks = 0;
      }

    } catch (err) {
      console.log("error", err);
    }

    const yesLength = this.selectedMilestones.YES.length;
    const sometimesLength = this.selectedMilestones.SOMETIMES.length;
    const milestonesLength = this.milestones.length;
    const noLength = this.selectedMilestones.NO.length;

    let total = 0;

    if (yesLength === milestonesLength) {
      total = 90;
    } else {
      // console.log('noLength', noLength);
      // console.log('yesLength', yesLength);
      // console.log('noLength', sometimesLength);
      total =
        ((yesLength * 9 + noLength * 2 + sometimesLength * 4) * 100) /
        ((yesLength + sometimesLength + noLength) * 10);
      if (isNaN(total)) {
        total = 0;
      }
    }

    this.score = Math.round(total);
    // let selectedMilestone = this.selectedMilestones;
    // this.store.dispatch(updateMilestoneState({ selectedMilestone, this.remark, score }));

    console.log('printing runtime score: ', this.score);

    this.addDefaultRemark(this.score);
  }



  getUploadedImagesCount() {
    this.milestoneService
      .fetchImages(
        this.student_id,
        this.catId,
        this.subCatId,
        this.dob_date,
        this.dob_month,
        this.dob_year
      )
      .subscribe({
        next: (data: any) => {

          const images = JSON.parse(data.data[0].images);
          this.photoCount = images.length;
          console.log("student id : ", this.student_id);
          console.log("cat id : ", this.catId);
          console.log("subcat id : ", this.subCatId);
          console.log("dob_date id : ", this.dob_date);
          console.log("dob_month id : ", this.dob_month);
          console.log("dob_year id : ", this.dob_year);
          console.log(
            'fetched images are : ',
            images,
            ' and photocount is : ',
            this.photoCount
          );
        },
        error: (error: any) => {
          console.error('Error in fetching images:', error);
        },
      });
  }

  goToCamera() {

    if (this.photoCount >= 0 && this.photoCount < 5) {
      const milestoneState: string = JSON.stringify(this.milestones);

      // creating one object for sending it to camera component and again getting it back from there.
      // so we can maintain the milestones-component state.

      const cameraData: object = {
        studentName: this.studentName,
        student_id: this.student_id,
        catId: this.catId,
        catName: this.catName,
        subcatId: this.subCatId,
        subcatName: this.subcat_name,
        dob_date: this.dob_date,
        dob_month: this.dob_month,
        dob_year: this.dob_year,
        classId: this.class_id,
        age: this.age,
        standard: this.standard,
        division: this.division,
        teacherName: this.teacherName,
        totalStudents: this.totalStudents,
        type: this.type,
        selectedMilestone: JSON.stringify(this.selectedMilestones),
        milestoneState: milestoneState,
        returnedFromCamera: false,
        score: this.score,
        remark: this.remarkText,
        uploadedPhotoCount: this.photoCount,
      };

      this.router.navigate(['/camera'], { queryParams: cameraData });
    } else {
      this.toastService.showAlert(this.translate.instant('milestone.photosLimit'), this.translate.instant('Note'));
    }
  }

  saveMilestone() {

    if (
      this.selectedMilestones.YES.length === 0 &&
      this.selectedMilestones.NO.length === 0 &&
      this.selectedMilestones.SOMETIMES.length === 0
    ) {
      this.toastService.showAlert(
        this.translate.instant('milestone.saveAtLeastOne'),
        this.translate.instant('Note')
      );
      return;
    }
    if (this.selectedMilestones.length !== 0) {
      if (this.type === 'edit') {
        // console.log("edit service");

        this.alertService.showAlert(
          this.translate.instant('Note'),
          this.translate.instant('alert.selected',{x: this.selectedMilestones.YES.length + this.selectedMilestones.NO.length + this.selectedMilestones.SOMETIMES.length , y : this.milestones.length}),
          'success',
          this.updateSavedMilestone.bind(this)
        );
      } else {
        // console.log("alert service");
        this.alertService.showAlert(
          this.translate.instant('Note'),
          this.translate.instant('alert.selected', {x: this.selectedMilestones.YES.length + this.selectedMilestones.NO.length + this.selectedMilestones.SOMETIMES.length , y : this.milestones.length}),
          'success',
          this.savedMilestoneConfirmation.bind(this)
        );
      }
    }
  }

  savedMilestoneConfirmation() {
    const milestone_ids = JSON.stringify(this.selectedMilestones);
    this.milestoneService
      .saveMilestone(
        this.catId,
        this.subCatId,
        this.class_id,
        this.student_id,
        this.school_id,
        milestone_ids,
        this.score,
        this.remarkText,
        this.dob_month,
        this.dob_date,
        this.dob_year
      )
      .subscribe({
        next: (res: any) => {
          history.pushState(null, 'null', window.location.href);

          this.router.navigate(
            [
              '/sub-categories',
              this.class_id,
              this.student_id,
              this.catId,
              this.catName,
            ],
            { queryParams: this.params }
          );
        },
        error: (error: any) => {
          console.error('Error saving milestones:', error);
        },
      });
  }

  updateSavedMilestone() {
    this.milestoneService
      .updateSavedMilestone(
        JSON.stringify(this.selectedMilestones),
        this.score,
        this.record_id,
        this.remarkText
      )
      .subscribe({
        next: (data: any) => {
          this.router.navigate(
            [
              '/sub-categories',
              this.class_id,
              this.student_id,
              this.catId,
              this.catName,
            ],
            { queryParams: this.params }
          );
        },
        error: (error: any) => {
          console.error('Error fetching milestones:', error);
        },
      });
  }

  getSavedMilestones(
    subcat_id: number,
    cat_id: number,
    class_id: number,
    student_id: number
  ) {
    // to get the already saved milestone results so as to edit them.
    let milestone_status: any;
    this.milestoneService
      .getSavedMilestone(subcat_id, cat_id, class_id, student_id)
      .subscribe({
        next: (data: any) => {

          this.savedMilestone = data.data[0];
          milestone_status = JSON.parse(data.data[0].milestone_ids);

          this.score = this.savedMilestone.result;
          this.record_id = this.savedMilestone.record_id;
          this.remarkText = data.data[0].remarks;
          // document.getElementById('remark').value = this.remark;

          this.selectedMilestones.YES = milestone_status.YES;
          this.selectedMilestones.NO = milestone_status.NO;
          this.selectedMilestones.SOMETIMES = milestone_status.SOMETIMES;

          // getting the saved remark


          this.milestones = this.milestones.map(milestone => {
            // Initialize properties
            milestone.red = false;
            milestone.green = false;
            milestone.yellow = false;
            milestone.clicks = 0;



            // Update properties based on milestone status
            if (milestone_status.YES.includes(milestone.milestone_id)) {
              // console.log("yes");
              milestone.green = true;
              milestone.clicks = 1;
            } else if (milestone_status.NO.includes(milestone.milestone_id)) {
              // console.log("no");

              milestone.red = true;
              milestone.clicks = 2;
            } else if (
              milestone_status.SOMETIMES.includes(milestone.milestone_id)
            ) {
              // console.log("sometimes");
              milestone.yellow = true;
              milestone.clicks = 3;
            }

            return milestone;
          });

          this.selectedMilestones.totalMilestones = this.milestones.length;
        },
        error: (error: any) => {
          console.error('Error fetching milestones:', error);
        },
      });
  }

  addDefaultRemark(score: number) {

    if (this.score === 0) {
      this.remarkText = '';
    } else if (this.score <= 26) {

      this.remarkText = `${this.studentName.split(' ')[0]} ${this.translate.instant('result.lagging')} ${this.subcat_name} ${this.translate.instant('result.development')}`;
    } else if (this.score >= 27 && this.score <= 66) {

      this.remarkText = `${this.studentName.split(' ')[0]} ${this.translate.instant('result.encouraged')} ${this.subcat_name} ${this.translate.instant('result.development')}`;

    } else if (this.score >= 67) {

      this.remarkText = `${this.studentName.split(' ')[0]} ${this.translate.instant('result.onTrack')} ${this.subcat_name} ${this.translate.instant('result.development')}`;

    }
  }

  goBack() {
    // window.history.back();
    // this.cat_id, this.class_id, this.student_id, this.dob_month, this.dob_date, this.dob_year
    console.log('cat name while returning to subcat: ', this.catName);
    const data = {
      dob_date: this.dob_date,
      dob_month: this.dob_month,
      dob_year: this.dob_year,
      teacherName: this.teacherName,
      studentName: this.studentName,
      standard: this.standard,
      division: this.division,
      totalStudents: this.totalStudents,
    };
    this.router.navigate(
      [
        '/sub-categories',
        this.class_id,
        this.student_id,
        this.catId,
        this.catName,
      ],
      { queryParams: data }
    );
  }
}
