@if (loader) {
<app-navbar [navbarDisplay]="navbarDisplay"></app-navbar>
<div class="parent-container">
  <app-namesection [studentName]="studentName" [age]="age" class="studentName"></app-namesection>

  <ul class="tabs">
    <li class="tab"><button class="tabBtn " [ngClass]=" {'activeTab': tabActive === 'summary'} "
        (click)="selectTab(1)">{{'summaryReport.sReport' | translate}}</button></li>
    <li class="tab"><button class="tabBtn" [ngClass]=" {'activeTab': tabActive === 'collated'} "
        (click)="selectTab(2)">{{'summaryReport.cReport' | translate}}</button></li>
  </ul>
  @if(selectedTab === 1) {
  <!-- summary report end -->

  <div class="summaryTab flex-cent flex-col">
    <h2 class="header-text">{{'summaryReport.summaryFrom' | translate}} <br>{{ studentName }} {{'summaryReport.parents' | translate}}</h2>

    <!-- parent details section. only visible in SAM's summary report and not in parents app -->
    <div class="parent-details-container">
      <h5 style="
          margin-top: 0.4rem;
          font-family: var(--font-proxima-bold);
          color: #424242;
        ">
        {{'summaryReport.parentDetails' | translate}}
      </h5>
      <div class="details">
        <p>{{'summaryReport.name' | translate}} {{ parent_first_name }} {{ parent_last_name }}</p>
      <p>{{'summaryReport.email' | translate}} {{ parent_email }}</p>
      <p>{{'summaryReport.contact' | translate}} {{ parent_contact }}</p>
      </div>
    </div>

    <!-- basic profile section -->
    @if(user_plan === 'basic') {
    <div class="basicProfile">
      <!-- <h5 class="under-development">Summary report for basic customers is under development</h5> -->
      <div class="categories-section">
        <h5>{{ 'summaryReport.basciStats' | translate}}</h5>
        <div class="categories">
          <div class="category-card basicCard" (click)="goToSubcatSummary('Basic', basicParentAverage)">
            <h5 style="
                  margin-top: 0.5rem;
                  color: #8c362b;
                  font-family: var(--font-proxima-bold);
                ">
              {{'basic' | translate}}
            </h5>
            <div class="cat-details">
              <div class="home-activities">
                <h6 style="font-size: 0.8rem">{{'summaryReport.customized' | translate}}<br />{{'summaryReport.recommendations' | translate}}</h6>
              <p>{{'total' | translate}} : {{basicTotal | hindiNumber}}</p>
              <p>{{'done' | translate}} : {{basicDone | hindiNumber}}</p>
              </div>

              <div class="average">
                @if(basicParentAverage !== 0){
                <h5 style="margin: 0">
                  {{'pending' | translate}}
                </h5>
                }@else {
                <h5>{{ 'average' | translate}}</h5>
                }


                <p class="average-score">
                  {{ basicParentAverage | hindiNumber}}%
                </p>
                @if(basicParentAverage !== 0){
                <p class="more-datails">
                  {{'moreDetails' | translate}} >>
                </p>
                }

              </div>

              <div class="chartDiv">
                <div id="basicParentPie" class="piechart"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    }@else {
    <div class="premium" #premium>
      <!-- bargraph section  -->
      <div class="bargraph-section">
        <h5>{{ 'summaryReport.overallStats' | translate}}</h5>
        <!-- <img src="../../../../../assets/img/bargraph.png" alt="bargraphImg"> -->
        <div id="chartdiv"></div>
      </div>

      <!-- categories section  -->
      <div class="categories-section">
        <h5 style="margin-top: 1rem; margin-bottom: 0">{{'summaryReport.catWiseStats' | translate}}</h5>
        @for (obj of CategoryResult; track obj) {
        <div class="categories">
          <div class="category-card" [ngClass]="{
                  lightYellow: obj.parentResult >= 26 && obj.parentResult <= 65,
                  lightRed: obj.parentResult <= 25,
                  lightGreen: obj.parentResult >= 66
                }" (click)="goToSubcatSummary(obj.cat_name, obj.parentResult)">
            <h5 style="
                    margin-top: 0.5rem;
                    color: #8c362b;
                    font-family: var(--font-proxima-bold);
                  ">
              {{ obj.cat_name }}
            </h5>
            <div class="cat-details">
              <div class="home-activities">
                <p>{{'summaryReport.customized' | translate}}<br />{{'summaryReport.recommendations' | translate}}</p>
              <p>{{'total' | translate}} : {{ obj.total | hindiNumber}}</p>
              <p>{{'done' | translate}} : {{ obj.done | hindiNumber}}</p>
              </div>

              <div class="average">
                @if(obj.parentResult === 0) {
                <h5 style="margin: 0">
                  {{'pending' | translate}}
                </h5>
                }@else {
                <h5>{{'average' | translate}}</h5>
                }


                <p class="average-score">{{ obj.parentResult | hindiNumber }}%</p>
                @if(obj.parentResult !== 0) {
                <p class="more-datails">
                  {{'moreDetails' | translate}} >>
                </p>
                }

              </div>

              <div class="chartDiv">
                <div id="{{ obj.parentChartId }}" class="piechart"></div>
              </div>
            </div>
          </div>
        </div>
        }

      </div>
    </div>
    }

  </div>
  } @else {


  <!-- collated results start -->

  <div class="collatedTab flex-cent flex-col">
    <h3 class="header-text">{{'summaryReport.collatedFrom' | translate}} <br>{{ studentName }} {{'summaryReport.parentsTeacher' | translate}}</h3>

    <!-- basic parent collated-->
    @if(user_plan === 'basic') {
    <div class="basicProfile basicCollatedCard">
      <!-- <h5 class="under-development">Summary report for basic customers is under development</h5> -->
      <div class="categories-section">
        <h5>{{ 'summaryReport.basciStats' | translate}}</h5>
        <div class="categories">
          <div class="category-card basicCard">
            <h5 style="
                  margin-top: 0.5rem;
                  color: #8c362b;
                  font-family: var(--font-proxima-bold);
                ">
              {{'basic' | translate}}
            </h5>
            <div class="cat-details">
              <div class="teacherResult">
                <h6>{{ 'summaryReport.teacherResults' | translate}} <br> {{basicParentAverage | hindiNumber}}%</h6>
                <div class="chartDiv">
                  <div id="basicParentPie" class="piechart"></div>
                </div>
              </div>

              <div class="average">
                @if(basicParentAverage === 0) {
                <h5 style="margin: 0">
                  {{'pending' | translate}}
                </h5>
                }@else {
                <h5>{{'average' | translate}}</h5>

                }

                <p class="average-score">
                  {{ basicCollatedAverage | hindiNumber}}%
                </p>
                @if( basicParentAverage !== 0) {
                <p class="more-datails">
                  {{'moreDetails' | translate}} >>
                </p>
                }

              </div>

              <div class="teacherResult">
                <h6>{{'summaryReport.teacherResults' | translate}} <br> {{ basicTeacherAverage | hindiNumber}}%</h6>
                <div class="chartDiv">
                  <div id="basicTeacherPie" class="piechart"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    } @else {
    <!-- premium parents collated -->
    <!-- categories section  -->
    <div class="categories-section">
      <!-- <h5 style="margin-top: 1rem; margin-bottom: 0">Category-wise Stats</h5> -->
      @for (obj of CategoryResult; track obj) {
      <div class="categories">
        <div class="category-card" [ngClass]="{
                lightYellow: obj.averageResult >= 26 && obj.averageResult <= 65,
                lightRed: obj.averageResult <= 25,
                lightGreen: obj.averageResult >= 66
              }" (click)="goToSubcatCollated(obj.cat_name, obj.averageResult)">
          <h5 style="
                  margin-top: 0.5rem;
                  color: #8c362b;
                  font-family: var(--font-proxima-bold);
                ">
            {{ obj.cat_name }}
          </h5>
          <div class="cat-details">
            <div class="parentResult">
              <h6>{{'summaryReport.parentsResults' | translate}} <br> {{obj.parentResult | hindiNumber}}%</h6>
              <div class="chartDiv">
                <div id="{{ obj.parentChartId }}" class="piechart"></div>
              </div>
            </div>

            <div class="average">
              @if(obj.parentResult === 0) {
              <h5 style="margin: 0">
                {{'pending' | translate}}
              </h5>
              }@else{
              <h5>{{'average' | translate}}</h5>
              }


              <p class="average-score">{{ obj.averageResult | hindiNumber }}%</p>
              @if(obj.parentResult !== 0) {
              <p class="more-datails">
                {{'moreDetails' | translate}} >>
              </p>
              }

            </div>

            <div class="teacherResult">
              <h6>{{'summaryReport.teacherResults' | translate}} <br> {{obj.teacherResult | hindiNumber}}%</h6>
              <div class="chartDiv">
                <div id="{{ obj.teacherChartId }}" class="piechart"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      }

    </div>
    }
  </div>
  }
</div>

<app-footer-navbar [class_id]="class_id"></app-footer-navbar>
}@else {
<app-spinner></app-spinner>
}