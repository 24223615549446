import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertService } from '../../services/alert/alert-service.service';

import { MilestoneService } from '../../services/milestone.service'
import { TranslateModule , TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert-box.component.html',
  styleUrls: ['./alert-box.component.css'],
  standalone: true,
  imports: [
    TranslateModule
  ]
})
export class AlertComponent implements OnInit, OnDestroy {
  private alertService = inject(AlertService);
  private milestoneService = inject(MilestoneService);
  private translate = inject(TranslateService);

  message: string | null = null;
  type: 'success' | 'error' | 'Note' | null = null;
  header: string  = "Note";
  okButton: boolean = false;
  notice : boolean = false
  confirmCallback?: () => void;
  private alertSubscription!: Subscription;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  ngOnInit() {
    this.alertSubscription = this.alertService.alert$.subscribe(alert => {
      this.message = alert.message;
      this.type = alert.type;
      if(this.type === 'Note'){
        this.notice = true;
      }
      this.header = alert.header;
      this.confirmCallback = alert.confirmCallback;
      // setTimeout(() => this.clearAlert(), 3000);
    });
  }

  ngOnDestroy() {
    if (this.alertSubscription) {
      this.alertSubscription.unsubscribe();
    }
  }

  clearAlert() {
    this.message = null;
    this.type = null;
    this.notice = false;
    this.confirmCallback = undefined;
  }

  confirm() {
    console.log("clicked confirm");
    if (this.confirmCallback) {
      this.confirmCallback();
    }
    this.clearAlert();
  }
}
