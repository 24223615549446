@if (loader) {
<div class="spinner">
  <app-navbar [teacherName]="teacherName" [schoolNavBar]="true" [standard]="standard" [division]="division"
    [totalStudents]="totalStudents" [class_id]="class_id"></app-navbar>
  <section class="d-flex align-items-center justify-content-center flex-column">
    <app-namesection [studentName]="studentName" [age]="age"></app-namesection>
    <div class="container">
      <span class="catName fw-bold text-center">{{cat_name}}</span>
      @for (subcat of subCategories; track subcat; let i = $index) {
      <div class="card-container ">
        <div class="card subcat" [attr.role]="'none'"
          (click)="subcat.completion === 'Yes' ? null : goToMilestones(subcat, 'add')"
          [ngClass]="{ 'completedCard': subcat.completion === 'Yes' }">
          <span class="subcatName ">{{  (i+1) | hindiNumber }}. {{ subcat.hasOwnProperty('cat_name_hi') ? subcat.cat_name_hi: subcat.cat_name }}</span>
          @if (subcat.completion === 'Yes') {
          <div>
            <div class="completed ">
              <img class="done_icon" src="../../../../assets/img/done.png" alt="completed">
              <img class="edit_icon" src="../../../../assets/img/edit_icon.png" alt="edit"
                (click)="goToMilestones(subcat,'edit')" role="none">
            </div>
          </div>
          } @else {
          <!-- <button class="completeNowBtn btn-warning">Complete now </button> -->
          <div class="notCompleted me-2">
            <img class="completeNow_icon" src="../../../../assets/img/complete_now.png" alt="completeNow">
          </div>
          }
          <ng-template #notCompleted>
            <div class="notCompleted me-2">
              <img class="completeNow_icon" src="../../../../assets/img/complete_now.png" alt="completeNow">
            </div>
          </ng-template>
        </div>
      </div>
      }
    </div>
  </section>
  <app-footer-navbar [class_id]="class_id"></app-footer-navbar>
</div>
} @else {
<app-spinner></app-spinner>
}